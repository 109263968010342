import React, { useState, useEffect } from "react";
import environment from "../../../config/environment";
import "./RoomOptions.scss";
import { Tooltip } from "@mui/material";

export function RoomOptions(props) {
  const [roomOptions, setRoomOptions] = useState([]);
  const hostelId = props.id;
  const getRoomOptions = async () => {
    const apiEndpoint = `${environment.api}/api/v1/hostel-with-room-details/${hostelId}`;
    const response = await fetch(apiEndpoint, {
      method: "GET",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const jsonResponse = await response.json();
    return jsonResponse;
  };

  const getRoomTypeIcon = (rt) => {
    let rtIcon;
    switch (rt) {
      case "Single Bed":
        rtIcon = "person";
        break;

      case "Double Bed":
        rtIcon = "group";
        break;

      case "Triple Bed":
        rtIcon = "diversity_3";
        break;

      default:
        rtIcon = "single_bed";
        break;
    }
    return rtIcon;
  };

  useEffect(() => {
    getRoomOptions().then((result) => {
      setRoomOptions(result.response);
    });
  }, []);

  return (
    <div className="RoomOptions">
      <div className="ro__header">Room Options</div>
      <div className="ro__container">
        {roomOptions.map((d) => {
          return (
            <div style={{width:"100%"}}
              className={d.noOfBeds === d.occupiedBeds ? "occupied" : ""}
              key={d.id}
            >
              <div>
                <Tooltip title={d.roomType.roomTypeName}>
                  <h3>{d.roomType.roomTypeName}</h3>
                </Tooltip>
                <Tooltip title={d.description}>
                  <p>{d.description}</p>
                </Tooltip>
              </div>
              <div className="ro__icon">
                <span className="mat-icon material-symbols-outlined">
                  {getRoomTypeIcon(d.roomType.roomTypeName)}
                </span>
                <div className="price">
                  <span></span>
                  <span>&#8377; {d.totalAmountWithTax}</span>
                  <span>/year</span>
                </div>
              </div>
              <div className="ro__add-info">
                <div>
                  <span>Security Deposit: </span>
                  <span>
                    <span className="mat-icon material-symbols-outlined">
                      currency_rupee
                    </span>
                    {d.securityDeposit}
                  </span>
                </div>
                <div>
                  <span>Available Rooms: </span>
                  <span>{d.noOfBeds - d.occupiedBeds}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
