import React from "react";
import "./HostelName.scss";
import { Tooltip } from "@mui/material";

export function HostelName(props) {
  const { name, rating, location, link } = props;
  return (
    <div className="HostelName">
      <div className="hn__container">
        <div className="hn__title">
          <h3>{name}</h3>
          <span>
            <span className="mat-icon material-symbols-outlined">
              star_rate
            </span>
            <span>{rating}</span>
           
          </span>
          {link && <span className="">
             <a style={{color:"blue"}} target="_blank" href={link}> Visit Google Profile </a>
          </span>}
        </div>
        <div className="hn__location">
          <Tooltip title={location}>
            <p>
              <span className="mat-icon material-symbols-outlined">
                location_on
              </span>
              <span>{location}</span>
            </p>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
