import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Container } from "@mui/system";
import Navbar from "../../components/navbar/Navbar";
import Loader from "../../components/loader/Loader";
import BackButton from "../../components/back-button/BackButton";
import "./ContactUs.scss";
import { Grid } from "@mui/material";
import MetaTag from "../../components/meta-tag/metatag";
import { Col, Row } from "antd";

export function ContactUs() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="ContactUs">
      <MetaTag hostelName={"Default"} />
      <Header />
      <Navbar />
      {loading ? (
        <Loader />
      ) : (
        <Container>
          {/* <div className="cu__top-toolbar">
            <BackButton />
          </div> */}
          <main className="cu__main-container">
            <section className="cu__left">
              <h2>Contact Us</h2>
              <Row>
                <Col span={24}>
                  <h3>Phone Number :</h3>
                  <label>8484020187 / 8484020162
                  </label>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <h3>Email :</h3>
                  <label>support@myhostelroom.com
                  </label>
                </Col>
              </Row>
            </section>
            <aside className="cu__right"></aside>
          </main>
        </Container>
      )}

      <Grid sx={{ mt: 20 }}>
        <Footer />
      </Grid>
    </div>
  );
}
