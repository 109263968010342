import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Box, Container } from "@mui/system";
import "./Footer.scss";

const hostels = [
  {
    name: "Hostels in BMCC Road",
    href: "hostel_in_BMCC_Road,_Deccan_Gymkhana,_Pune,_Maharashtra,_India",
    lat: 18.5193398,
    lng: 73.8363314,
  },
  {
    name: "Hostels in SB Road",
    href: "hostel_in_SB_Road_Pune,_Senapati_Bapat_Road,_Model_Colony,_Gokhalenagar,_Pune,_Maharashtra,_India",
    lat: 18.5299554,
    lng: 73.833075,
  },
  {
    name: "Hostels in Viman Nagar",
    href: "hostel_in_Viman_Nagar,_Pune,_Maharashtra,_India",
    lat: 18.5647322,
    lng: 73.9071519,
  },
  {
    name: "Hostels in Model Colony",
    href: "hostel_in_Model_Colony,_Shivajinagar,_Pune,_Maharashtra,_India",
    lat: 18.5300867,
    lng: 73.8380285,
  },
  {
    name: "Hostels in Shaniwar Peth",
    href: "hostel_in_Shaniwar_Peth,_Pune,_Maharashtra,_India",
    lat: 18.5193895,
    lng: 73.8596567,
  },
  {
    name: "Hostels in Wakad",
    href: "hostel_in_Wakad,_Pimpri-Chinchwad,_Maharashtra,_India",
    lat: 18.5904487,
    lng: 73.77082879999999,
  },
  {
    name: "Hostels in Rambaug Colony",
    href: "hostel_in_Rambaug_Colony,_Kothrud,_Pune,_Maharashtra,_India",
    lat: 18.5183446,
    lng: 73.8178236,
  },
  {
    name: "Hostels in Shivajinagar",
    href: "hostel_in_Shivajinagar,_Pune,_Maharashtra,_India",
    lat: 18.5300867,
    lng: 73.8380285,
  },
  {
    name: "Hostels in F.C. Road",
    href: "hostel_in_F.C._Road,_Shivajinagar,_Pune,_Maharashtra,_India",
    lat: 18.5193398,
    lng: 73.8363314,
  },
  {
    name: "Hostels in Dhankawadi",
    href: "hostel_in_Dhankawadi,_Pune,_Maharashtra,_India",
    lat: 18.4601544,
    lng: 73.84938439999999,
  },
  {
    name: "Hostels in Bibwewadi",
    href: "hostel_in_Bibwewadi,_Pune,_Maharashtra,_India",
    lat: 18.4614983,
    lng: 73.8620586,
  },
  {
    name: "Hostels in Kothrud",
    href: "hostel_in_Kothrud,_Pune,_Maharashtra,_India",
    lat: 18.513863,
    lng: 73.8180127,
  },
  {
    name: "Hostels in Karve Nagar",
    href: "hostel_in_Karve_Nagar,_Pune,_Maharashtra,_India",
    lat: 18.511404,
    lng: 73.81963470000001,
  },
  {
    name: "Hostels in Deep Bangla Chowk",
    href: "hostel_in_Deep_Bangla_Chowk,_Swastik_Society,_Model_Colony,_Shivajinagar,_Pune,_Maharashtra",
    lat: 18.5299554,
    lng: 73.833075,
  },
];

const chunkArray = (arr, size) => {
  return arr.reduce(
    (acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]),
    []
  );
};
export default function Footer(props) {
  const footerStyle = {};

  const chunkedHostels = chunkArray(hostels, 4);
  const navigate = useNavigate();
  const handleClickLink = (event, place, lat, long) => {
    event.preventDefault();
    navigate(`/hostel-list/${place}`, {
      state: { lat: lat, lng: long },
    });
  };

  return (
    <footer className="component__footer" style={{ padding: 25 }}>
      <Container>
      <div className="component__footer-main"  style={{ display: "flex", gap: "60px" }}>
          {" "}
          {/* Grid layout */}
          {chunkedHostels.map((column, columnIndex) => (
            <ul
              key={columnIndex}
              style={{ listStyleType: "none", padding: 10 }}
            >
              {column.map((hostel, index) => {

                return (
                  <li key={index} style={{ listStyleType: "none", padding: 3 }}>
                    <a
                      className="highlighted-link"
                      href={hostel.href}
                      onClick={(e) =>
                        handleClickLink(
                          e,
                          hostel.href,
                          hostel.lat,
                          hostel.long
                        )
                      }
                    >
                      {hostel.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          ))}
        </div>
        <hr style={{ border: "0.2px solid ligthgray", margin: "20px 0" }} />
      </Container>
      <Container>
        <div className="component__footer-main">
          <p>Copyright {new Date().getFullYear()} | Kele Hostels Pvt. Ltd., All Rights Reserved</p>

          <ul>
            <li>
              <Link to="/faqs">FAQs</Link>
              <Link to="/privacy-policy">Privacy Policy</Link>
              <Link to="/terms-of-use">Terms of use</Link>
              <Link to="/blog">Blogs</Link>
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
          <ul>
            <li style={{ height: 30, paddingTop: 5 }}>
              <a
                href="https://www.facebook.com/myhostelroom?mibextid=LQQJ4d"
                target="_blank"
              >
                <span class="facebook-links">
                  <img
                    alt="Facebook"
                    src="https://www.myhostelroom.com/images/facebook.svg"
                  />
                </span>
              </a>
              <a
                href="https://www.instagram.com/myhostelroom_/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                target="_blank"
              >
                <span class="insta-links">
                  <img
                    alt="instagtramicon"
                    src="https://www.myhostelroom.com/images/instagram.svg"
                  />
                </span>
              </a>
              <a
                href="https://www.linkedin.com/company/myhostelroom/"
                target="_blank"
              >
                <span class="linkedin-links">
                  <img
                    alt="linkedinicon"
                    src="https://www.myhostelroom.com/images/linked-in.svg"
                  />
                </span>
              </a>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  );
}
